<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { BookingRequestProps, BookingRequestEmit } from '#imports'

const showCalendar = ref(false)

const emits = defineEmits<BookingRequestEmit>()

const props = withDefaults(defineProps<BookingRequestProps>(), {
  formId: 'booking-request-form',
  loading: false,
  bookingMinDays: 2,
  prices: null,
  weeklyDiscount: false,
  hasInstantBookingActivated: false,
  openDays: null,
  hasForcedDepartureMorning: false,
  hasForcedDepartureAfternoon: false,
  hasForcedArrivalMorning: false,
  hasForcedArrivalAfternoon: false,
  hasHalfDayActivated: false,
  is200kmFree: false,
  isUnlimitedKmActivated: false,
  isUnlimitedKmFree: false,
  weeklyFactor: null,
  isSelfInsured: false,
})

const {
  form,
  kilometersOptions,
  hoursConstraintsForSelectedDate,
  showWeeklyDiscountTip,
  onHourChange,
  onCalendarInput,
  onFormChange,
  onSubmit,
} = useBookingRequest({ props, emits, showCalendar })

const { getReferentialOptions } = useReferentialStore()

const onDateRangeClick = () => {
  showCalendar.value = !showCalendar.value
}

const datepicker = ref(null)
onClickOutside(datepicker, () => {
  showCalendar.value = false
})

watch(() => form.value.kilometers, (_newKm, oldKm) => {
  // No need to emit form change events on first value
  if (oldKm === null) {
    return
  }
  onFormChange()
})
</script>

<template>
  <div id="booking-request-widget">
    <YscLoadingOverlay
      v-if="loading"
      :loading="loading"
    />

    <div class="space-y-4">
      <YscForm
        :id="formId"
        class="!space-y-6"
        @submit="onSubmit"
      >
        <!-- START Dates & hours -->
        <div class="relative">
          <AppCamperBookingRequestWidgetDateTrigger
            ref="date-picker-trigger"
            :date-range="form.dateRange"
            :has-half-day-activated="props.hasHalfDayActivated"
            @click="onDateRangeClick"
          />
          <AppCamperBookingRequestWidgetCalendar
            ref="datepicker"
            :value="form.dateRange ?? undefined"
            class="absolute top-full z-50 mt-2"
            :show="showCalendar"
            :availabilities="availabilities"
            :booking-min-days="bookingMinDays"
            :open-days="openDays"
            :has-forced-arrival-morning="hasForcedArrivalMorning"
            :has-forced-departure-afternoon="hasForcedDepartureAfternoon"
            :has-half-day-activated="hasHalfDayActivated"
            :min-date-future-rental="minDateFutureRental"
            :max-date-future-rental="maxDateFutureRental"
            @input="onCalendarInput"
          />
          <AppCamperBookingRequestWidgetHours
            v-if="props.hasHalfDayActivated && form.dateRange && form.dateRange.start && form.dateRange.end"
            :value="form.hours"
            :has-forced-arrival-morning="hoursConstraintsForSelectedDate.forceArrivalMorning"
            :has-forced-arrival-afternoon="hoursConstraintsForSelectedDate.forceArrivalAfternoon"
            :has-forced-departure-morning="hoursConstraintsForSelectedDate.forceDepartureMorning"
            :has-forced-departure-afternoon="hoursConstraintsForSelectedDate.forceDepartureAfternoon"
            :referential-hour-from-options="getReferentialOptions('hour_from')"
            :referential-hour-to-options="getReferentialOptions('hour_to')"
            @change="onHourChange"
          />
          <AppCamperBookingRequestWidgetWeeklyDiscountTip
            v-if="showWeeklyDiscountTip"
            :weekly-factor="weeklyFactor"
            class="mt-4"
          />
        </div>
        <!-- END Dates & hours -->

        <!-- START Kilometers -->
        <div v-if="prices">
          <template
            v-if="kilometersOptions.length > 1"
          >
            <span class="block font-semibold">{{ $t('data.booking.km_option.label') }}</span>
            <YscToggleGroup
              v-model.number="form.kilometers"
              class="mt-2 mb-6 text-sm"
              name="kilometers"
              grow
              :options="kilometersOptions"
              required
            />
          </template>
          <template v-else>
            <YscDivider />
            <div class="flex justify-between items-center my-1 py-2 sm:py-3 -mb-3">
              <span class="block">
                {{ $t('data.booking.km_option.label') }}
              </span>
              <span class="block font-semibold">
                {{ kilometersOptions[0].text }}
              </span>
            </div>
          </template>
        </div>
        <!-- END Kilometers -->
      </YscForm>
      <div v-if="prices && form.kilometers !== undefined && form.kilometers !== null">
        <YscDivider />
        <!-- START Price details -->
        <AppCamperBookingRequestWidgetPriceDetails
          :prices="prices"
          :kilometers="form.kilometers"
          :is-self-insured="props.isSelfInsured"
        />
        <!-- END Price details -->
      </div>

      <div>
        <YscButtonSubmit
          class="w-full"
          :loading="loading"
          :form="formId"
        >
          <template v-if="prices">
            {{ hasInstantBookingActivated ? $t('components.app_camper_booking_request_widget.cta.book_instant') : $t('components.app_camper_booking_request_widget.cta.book') }}
          </template>
          <template v-else>
            {{ $t('components.app_camper_booking_request_widget.cta.check_availability') }}
          </template>
        </YscButtonSubmit>
      </div>
    </div>
  </div>
</template>

<style>
@media screen(lg) {
  #booking-request-widget .vc-container {
    @apply absolute left-auto right-0;
  }
}
</style>
