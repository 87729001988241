import type { VehicleImageType, VehicleResponse } from '@yescapa-dev/ysc-api-js/legacy'

export const useGetVehicleAlternateText = () => {
  const { getTranslatedPictureType } = useGetTranslatedPictureType()

  return {
    getVehicleAlternateText: (params: { photoData: VehicleImageType, vehicleBrand?: VehicleResponse['brand'] }) => {
      let vehicleBrandLabel = ''
      if (params.vehicleBrand) {
        vehicleBrandLabel = `${params.vehicleBrand.name ?? ''} ${params.vehicleBrand.model ?? ''}`.trim()
      }
      return `${getTranslatedPictureType(params.photoData.vehicle_image_type)} ${vehicleBrandLabel} - Yescapa`
    },
  }
}
