/** Perform a shallow comporaison between two simple objects */
export function shallowEqual(first: Record<string, any> | null | undefined, second: Record<string, any> | null | undefined) {
  if (first === second) {
    return true
  }

  if (!first || !second) {
    return false
  }

  const firstKeys = Object.keys(first)
  const secondKeys = Object.keys(second)

  if (firstKeys.length !== secondKeys.length) {
    return false
  }

  for (const key of firstKeys) {
    if (first[key] !== second[key] || !Object.prototype.hasOwnProperty.call(second, key)) {
      return false
    }
  }

  return true
}
