<script setup lang="ts">
import type { VehicleEquimentsResponse, VehicleResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { vehicleEquipmentsOverview, equipmentsCategoryMap } from '~/utils/vehicle'

interface Props {
  vehicle: VehicleResponse
  linkQueryMore: string
}

defineEmits<{
  openModal: [string]
}>()

const props = defineProps<Props>()

const { getTranslatedVehicleEquipment } = useGetTranslatedVehicleEquipment()

const displayedEquipments = computed(() => Object.keys(props.vehicle.equipments as VehicleEquimentsResponse).filter((key) => {
  const value = props.vehicle.equipments[key as keyof VehicleEquimentsResponse]
  return value || (Array.isArray(value) && value.length)
}))

const displayedEquipmentsOverview = computed(() => {
  const overviewEquipments = displayedEquipments.value.filter(item => vehicleEquipmentsOverview.includes(item))
  while (overviewEquipments.length < 9 && displayedEquipments.value.find(eq => !overviewEquipments.includes(eq))) {
    const item = displayedEquipments.value.find(
      eq =>
        !overviewEquipments.includes(eq)
        && equipmentsCategoryMap[eq]?.category !== 'autonomy-details'
        && !equipmentsCategoryMap[eq]?.isNumber,
    )
    if (!item) {
      break
    }
    overviewEquipments.push(item)
  }
  return overviewEquipments
})
</script>

<template>
  <ul
    v-if="displayedEquipmentsOverview.length"
    class="grid grid-cols-2 gap-x-8 gap-y-2"
  >
    <li
      v-for="item in displayedEquipmentsOverview"
      :key="`displayedEquipments_${item}`"
      class="truncate"
    >
      {{ getTranslatedVehicleEquipment(item) }}
    </li>
  </ul>
  <p
    v-else
    class="italic"
  >
    {{ $t('commons.no_information') }}
  </p>
  <div
    v-if="displayedEquipments.length"
    class="mt-2"
  >
    <NuxtLink
      :to="useGetRouteWith({ query: { ...useRoute().query, more: linkQueryMore } })"
      class="link link-primary"
      use-icon
      arrow
      @click="$emit('openModal', linkQueryMore)"
    >
      {{ $t('pages.campers.see_all_equipments') }}
      <YscIconsArrowRight class="w-5 h-5" />
    </NuxtLink>
  </div>
</template>
