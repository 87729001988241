<script setup lang="ts">
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { InsuranceItem, InsuranceUserConditions, InsuranceUserConditionsResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { YSC_API_INSURANCE_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  slug: string
  idUser: number
  drivingLicenceType?: string | null
  is247Insurance?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  drivingLicenceType: null,
  is247Insurance: false,
})

const { t } = useI18n()

const { pending, data: current } = await useAsyncData('modal-insurance-details', async () => {
  const { $api } = useYscApi()
  const { $errorManager } = useErrorManager()
  let insurance: InsuranceItem | { condition: InsuranceUserConditions } | null = null
  try {
    if (props.slug === Constants.INSURANCES.OWNER_MANAGED_INSURANCE_SLUG) {
      const condition = await $api.insurance.getUserCondition(props.idUser)
      insurance = { condition }
    }
    else {
      insurance = await $api.insurance.getBySlug(props.slug)
    }
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_INSURANCE_ERROR })
    }
  }
  return insurance
})

const hasInsurance = props.slug !== Constants.INSURANCES.OWNER_MANAGED_INSURANCE_SLUG

const insuranceConditions = computed((): Partial<InsuranceUserConditions> | Partial<InsuranceUserConditionsResponse> => {
  if (!current.value?.condition) {
    return {
      extra_comments: t('pages.campers.insurance_self_insured_default'),
    }
  }
  return {
    ...current.value.condition,
  }
})

const insuranceLink = computed(() => {
  if (current.value && 'insurance_url' in current.value && current.value.insurance_url) {
    return current.value.insurance_url
  }
  else if ('insurance_file' in insuranceConditions.value) {
    return insuranceConditions.value.insurance_file
  }
  return undefined
})

defineEmits<{
  close: []
}>()
</script>

<template>
  <YscModal @close="$emit('close')">
    <template #title>
      {{ hasInsurance ? $t('pages.campers.included') : $t('pages.campers.insurance_included_self_insured') }}
    </template>

    <AppLoading v-if="pending" />

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-else
      class="prose"
    >
      <div class="whitespace-pre-line">
        <template v-if="hasInsurance">
          <div
            v-html="
              $t('pages.campers.insurance_details_multirisk_full_html', {
                age_string: insuranceConditions.min_drivers_age,
                driver_license_string: insuranceConditions.min_driving_licence_age,
              })
            "
          />
          <AppCamperInsuranceConditionsItems
            :insurance-min-age="insuranceConditions.min_drivers_age"
            :insurance-max-age="insuranceConditions.max_drivers_age ?? undefined"
            :driving-licence-type="drivingLicenceType ?? undefined"
            :min-driving-licence-age="insuranceConditions.min_driving_licence_age ?? undefined"
          />
          <div
            v-if="is247Insurance"
            v-html="$t('pages.campers.insurance_details_assistance_alltime_full_html')"
          />
          <div
            v-else
            v-html="$t('pages.campers.insurance_details_assistance_full_html')"
          />
          <div v-html="$t('pages.campers.insurance_details_payment_full_html')" />
          <div v-html="$t('pages.campers.insurance_details_guarantee_full_html')" />
        </template>
        <template v-else>
          <div
            v-html="
              $t('pages.campers.insurance_details_self_insured_insurance_full_html', {
                insurance_string: insuranceConditions.extra_comments,
              })
            "
          />
          <AppCamperInsuranceConditionsItems
            :insurance-min-age="insuranceConditions.min_drivers_age"
            :insurance-max-age="insuranceConditions.max_drivers_age ?? undefined"
            :driving-licence-type="drivingLicenceType ?? undefined"
            :min-driving-licence-age="insuranceConditions.min_driving_licence_age ?? undefined"
          />
          <div v-html="$t('pages.campers.insurance_details_self_insured_assistance_full_html')" />
          <div v-html="$t('pages.campers.insurance_details_payment_full_html')" />
          <div v-html="$t('pages.campers.insurance_details_guarantee_full_html')" />
        </template>
      </div>
    </div>

    <template
      v-if="insuranceLink || (current && 'assistance_url' in current && current.assistance_url)"
      #footer
    >
      <div class="flex items-center justify-end space-x-10 py-4">
        <NuxtLink
          v-if="insuranceLink"
          :to="insuranceLink"
          class="link link-primary"
          target="_blank"
        >
          {{ $t('pages.campers.insurance_details') }}
        </NuxtLink>

        <NuxtLink
          v-if="current && 'assistance_url' in current && current.assistance_url"
          :to="current.assistance_url"
          class="link link-primary"
          target="_blank"
        >
          {{ $t('pages.campers.assistance_details') }}
        </NuxtLink>
      </div>
    </template>
  </YscModal>
</template>
