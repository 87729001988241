<script setup lang="ts">
interface Props {
  about: string
  firstName: string
  pictureUrl?: string
  isBestOwner?: boolean
  isVerified?: boolean
  description?: string
  descriptionSourceLanguage?: string
  canTranslateDescription?: boolean
  canTranslateOnCreated?: boolean
}

withDefaults(defineProps<Props>(), {
  pictureUrl: undefined,
  description: undefined,
  descriptionSourceLanguage: undefined,
  canTranslateDescription: false,
  canTranslateOnCreated: false,
})

defineEmits<{
  close: []
}>()

const showTranslatedDescription = ref(false)
const translation = ref(null)
const loading = ref(false)

// FIXME onTranslate does not exist
// await useAsyncData('modal-camper-description',async () => {
//   if (props.canTranslateOnCreated) {
//     await onTranslate
//   }
// })
// async fetch() {
//   if (this.canTranslateOnCreated) {
//     await this.onTranslate()
//   }
// },
</script>

<template>
  <YscModal @close="$emit('close')">
    <template #title>
      {{ about }}
    </template>

    <div
      class="relative"
      :class="{ 'pointer-events-none select-none': loading }"
    >
      <YscLoadingOverlay
        v-if="loading"
        :loading="loading"
      />
      <div class="flex flex-wrap items-end justify-between gap-4">
        <AppUserProfileCard
          :first-name="firstName"
          :picture-url="pictureUrl"
          :is-verified="isVerified"
          :is-best-owner="isBestOwner"
        >
          <template #description>
            {{ $t('pages.campers.campers_owner') }}
          </template>
        </AppUserProfileCard>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="prose mt-8"
        :lang="showTranslatedDescription && translation ? $i18n.locale : descriptionSourceLanguage"
        v-html="showTranslatedDescription && translation ? translation : description"
      />
    </div>
  </YscModal>
</template>
