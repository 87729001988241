<script setup lang="ts">
import { CAMPER_REQUEST_DETAILS_EVENT } from '~/utils/analytics/camper'

interface Props {
  loading?: boolean
  formId?: string
  splittedPrice?: string[]
  price?: string
  selectedDates?: {
    start: Date | null
    end: Date | null
  } | null
  ctaLabel?: string | null
  isModal?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  formId: 'booking-request-form',
  price: undefined,
  splittedPrice: () => ['', ''],
  selectedDates: null,
  ctaLabel: null,
  isModal: false,
})

const emits = defineEmits<{
  'submit-invisible-form': []
  'submit': []
}>()

const hasSelectedDates = computed(() => props.selectedDates?.start && props.selectedDates?.end)

const onClick = () => {
  const form = document.getElementById(props.formId) as HTMLInputElement
  if (form) {
    if (!form.checkValidity()) {
      form.reportValidity()
      return
    }
    const { event } = useGtag()
    event(CAMPER_REQUEST_DETAILS_EVENT)
    emits('submit')
    return
  }

  emits('submit-invisible-form')
}
</script>

<template>
  <div class="relative flex w-full items-center justify-between space-x-2">
    <div
      v-if="!isModal"
      class="flex flex-wrap"
    >
      <i18n-t
        v-if="!hasSelectedDates"
        keypath="pages.campers.starting_price_dynamic"
        tag="span"
        class="text-sm"
        scope="global"
      >
        <template #price_string>
          <span class="font-semibold text-base">{{ splittedPrice[0] }}</span>{{ splittedPrice[1] }}
        </template>
      </i18n-t>
      <template v-else>
        <p class="whitespace-pre">
          <span class="font-semibold">{{ price }}</span>
        </p>

        <span
          class="link text-sm font-light underline"
          :class="{ 'whitespace-nowrap': hasSelectedDates }"
        >
          <template v-if="selectedDates?.start && selectedDates?.end">
            {{ $d(selectedDates.start, 'M.short D.numeric') }} - {{ $d(selectedDates.end, 'M.short D.numeric') }}
          </template>
        </span>
      </template>
    </div>

    <div class="w-full text-right">
      <YscButtonSubmit
        :loading="loading"
        :class="{ 'w-full': isModal }"
        @click="onClick"
      >
        {{ ctaLabel ?? $t('components.app_camper_booking_request_widget.cta.book') }}
      </YscButtonSubmit>
    </div>
  </div>
</template>
