<script setup lang="ts">
import type { BookingRequestPriceResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { CAMPER_PRICING_DETAILS_EVENT } from '~/utils/analytics/camper'

interface Props {
  prices: BookingRequestPriceResponse
  kilometers: number
  isSelfInsured: boolean
  show2x?: boolean
}

const props = withDefaults(defineProps<Props>(), { show2x: false })
const showPricingDetails = ref(false)
const { getReferentialMaps } = useReferentialStore()

const totalKm = computed(() => {
  if (!props.prices) {
    return undefined
  }
  return props.prices.nb_days * props.kilometers
})

watch(showPricingDetails, () => {
  if (showPricingDetails.value) {
    const { event } = useGtag()
    event(CAMPER_PRICING_DETAILS_EVENT)
  }
})
</script>

<template>
  <div class="space-y-2 sm:space-y-3">
    <YscDivider class="lg:hidden" />
    <div class="flex justify-between items-center">
      <span class="block">
        {{ $t('components.app_camper_booking_request_widget.total_price') }}
      </span>
      <span class="block font-semibold">
        {{ $n(prices.price.value, {
          ...getReferentialMaps('currency')[prices.price.currency],
          minimumFractionDigits: Number.isInteger(prices.price.value) ? 0 : 2,
          maximumFractionDigits: Number.isInteger(prices.price.value) ? 0 : 2,
        }) }}
      </span>
    </div>

    <div
      v-if="prices.price_2x_first"
      class="flex justify-between items-center -mt-3"
    >
      <span class="block text-sm">{{ $t('components.app_camper_booking_request_widget.price_2x') }}</span>
    </div>
    <YscDivider
      class="hidden lg:block"
    />
    <div
      v-if="show2x"
      class="lg:hidden"
    >
      <div class="flex items-center justify-between gap-3 mb-3">
        <i18n-t
          keypath="pages.campers.pay_cb2x_dynamic"
          tag="p"
          scope="global"
          class="text-sm text-gray-500"
        >
          <template #emphasis_string>
            <span class="text-pink-500">
              {{ $t('pages.campers.pay_cb2x_emphasis') }}
            </span>
          </template>
        </i18n-t>
        <div class="ml-4">
          <YscIconsPaymentTwoTimes
            class="h-6 w-6 grow"
            highlight="text-pink-500"
          />
        </div>
      </div>
    </div>
    <YscDivider class="lg:hidden" />
    <!-- override YscDetailsAction margin/paddings -->
    <div class="!mt-0">
      <YscDetailsAction v-model="showPricingDetails">
        <template #summary>
          {{ $t('components.app_camper_booking_request_widget.pricing_details.title') }}
        </template>
        <template #marker>
          <span
            class="link link-primary block font-semibold text-sm"
          >
            <template v-if="showPricingDetails">
              {{ $t('commons.actions.hide') }}
            </template>
            <template v-else>
              {{ $t('commons.actions.show') }}
            </template>
          </span>
        </template>
        <AppPriceDetails
          v-if="showPricingDetails"
          class="mt-2 lg:hidden"
          :prices="prices"
          :km-option="kilometers"
          :total-km="totalKm"
          :is-self-insured="isSelfInsured"
          user-type="guest"
        />
      </YscDetailsAction>
      <YscDivider />
      <span class="lg:hidden text-sm inline-flex my-1 py-2 sm:py-3">
        {{ $t('components.app_camper_booking_request_widget.no_obligation') }}
      </span>
    </div>
    <div class="hidden lg:block">
      <YscModal
        v-if="showPricingDetails"
        class="hidden lg:inline-flex"
        @close="showPricingDetails = false"
      >
        <template #title>
          {{ $t('components.app_camper_booking_request_widget.pricing_details.title') }}
        </template>
        <AppPriceDetails
          class="mt-2"
          :prices="prices"
          :km-option="kilometers"
          :total-km="totalKm"
          :is-self-insured="isSelfInsured"
          user-type="guest"
        />
      </YscModal>
    </div>
  </div>
</template>
