<script setup lang="ts">
interface Props {
  insuranceMinAge?: number
  insuranceMaxAge?: number
  drivingLicenceType?: string
  minDrivingLicenceAge?: number
}

const props = withDefaults(defineProps<Props>(), {
  insuranceMinAge: undefined,
  insuranceMaxAge: undefined,
  drivingLicenceType: undefined,
  minDrivingLicenceAge: undefined,
})

const i18nAgePath = computed(() => {
  if (!props.insuranceMinAge && !props.insuranceMaxAge) {
    return null
  }
  if (props.insuranceMinAge && props.insuranceMaxAge) {
    return 'pages.campers.both_age_dynamic'
  }
  if (props.insuranceMinAge) {
    return 'pages.campers.min_age_dynamic'
  }

  return 'pages.campers.max_age_dynamic'
})
</script>

<template>
  <ul class="flex flex-wrap gap-2 pl-0">
    <i18n-t
      v-if="i18nAgePath"
      :keypath="i18nAgePath"
      class="flex flex-wrap items-center whitespace-pre rounded-3xl bg-gray-100 px-3 py-2 text-sm"
      tag="li"
    >
      <template
        v-if="insuranceMinAge"
        #min_age_string
      >
        <strong>{{ $n(insuranceMinAge, { style: 'unit', unit: 'year', unitDisplay: 'long' }) }}</strong>
      </template>
      <template
        v-if="insuranceMaxAge"
        #max_age_string
      >
        <strong>{{ $n(insuranceMaxAge, { style: 'unit', unit: 'year', unitDisplay: 'long' }) }}</strong>
      </template>
    </i18n-t>
    <i18n-t
      keypath="pages.campers.licence_since_dynamic"
      class="flex flex-wrap items-center whitespace-pre rounded-3xl bg-gray-100 px-3 py-2 text-sm"
      tag="li"
      scope="global"
    >
      <template #licence_string>
        <strong>{{ $t('pages.campers.driving_licence_dynamic', { licence_type_string: drivingLicenceType }) }}</strong>
      </template>
      <template
        v-if="minDrivingLicenceAge"
        #since_string
      >
        <strong>{{ $n(minDrivingLicenceAge, { style: 'unit', unit: 'year', unitDisplay: 'long' }) }}</strong>
      </template>
    </i18n-t>
  </ul>
</template>
