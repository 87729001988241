<template>
  <YscModal @close="$emit('close')">
    <template #title>
      {{ $t('commons.equipments') }}
    </template>

    <div class="divide-y divide-gray-200">
      <div
        v-for="group in displayedEquipmentsByCategory"
        :key="`apmce-${group.category.key}`"
        class="py-6 first:pt-0"
      >
        <div class="flex items-center space-x-4">
          <component
            :is="group.category.icon"
            class="h-5 w-5"
          />
          <YscHeading level="3">
            {{ getTranslatedVehicleEquipmentCategory(group.category.key) }}
          </YscHeading>
        </div>

        <ul class="mt-4 grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-3">
          <li
            v-for="item in group.list"
            :key="item.key"
          >
            {{ getTranslatedVehicleEquipment(item.key) }}
            <span
              v-if="item.subtext"
              class="block italic"
            >{{ item.subtext }}</span>
          </li>
        </ul>
      </div>

      <div
        v-if="equipments.others"
        class="pt-4"
      >
        <div class="flex items-center space-x-4">
          <YscIconsActivity class="h-5 w-5" />
          <YscHeading level="3">
            {{ $t('components.app_search.filters.others') }}
          </YscHeading>
        </div>

        <p class="mt-4">
          {{ equipments.others }}
        </p>
      </div>
    </div>
  </YscModal>
</template>

<script setup lang="ts">
import { isString } from '@yescapa-dev/ysc-api-js/modern'
import type { VehicleEquimentsResponse } from '@yescapa-dev/ysc-api-js/legacy'

interface Props {
  equipments: VehicleEquimentsResponse
}
const props = defineProps<Props>()

const { t, n } = useI18n()
const { getReferentialOptions } = useReferentialStore()

const { getTranslatedVehicleEquipment } = useGetTranslatedVehicleEquipment()
const { getTranslatedVehicleEquipmentCategory } = useGetTranslatedVehicleEquipmentCategory()

const categoryDisplayOrder = [
  { key: 'driving', icon: resolveComponent('YscIconsDriving') },
  { key: 'life_on_board', icon: resolveComponent('YscIconsLifeOnBoard') },
  { key: 'kitchen_meal', icon: resolveComponent('YscIconsKitchen') },
  { key: 'toilet', icon: resolveComponent('YscIconsToilet') },
  { key: 'outside', icon: resolveComponent('YscIconsOutdoor') },
  { key: 'autonomy-details', icon: resolveComponent('YscIconsAutonomous') },
]

type EquipementDisplayData = {
  category: string
  key: keyof VehicleEquimentsResponse
  subtext: string | false
}

const displayedEquipments = computed<Record<string, EquipementDisplayData[]>>(() =>
  typedObjectKeys(props.equipments)
    .filter((key) => {
      const value = props.equipments[key]
      return Array.isArray(value) ? value.length : value
    }).map((key) => {
      const { category = null, referentialOptionsKey = null, isNumber = false, unit = null } = equipmentsCategoryMap[key] || {}
      let subtext: string | false = false
      const subValue = props.equipments[key]
      if (referentialOptionsKey && Array.isArray(subValue)) {
        subtext = subtext = subValue
          .map((x) => {
            const options = getReferentialOptions(referentialOptionsKey)
            const { text } = options.find(({ value }) => value === x) || {}
            return text
          })
          .join(', ')
      }
      else if (isNumber) {
        const subValueAsNumber = Number(subValue)

        if (unit) {
          subtext = isString(unit)
            ? `${n(subValueAsNumber)} ${t(unit)}`
            : n(subValueAsNumber, { style: 'unit', ...unit })
        }
        else {
          subtext = n(subValueAsNumber)
        }
      }
      return { category, key, subtext }
    }).filter((eq): eq is EquipementDisplayData => eq.category !== null)
    .reduce<Record<string, EquipementDisplayData[]>>((acc, cur) => {
      if (!acc[cur.category]) {
        acc[cur.category] = []
      }
      acc[cur.category].push(cur)

      return acc
    }, {}),
)

const displayedEquipmentsByCategory = computed(() => categoryDisplayOrder.map(category => ({
  category,
  list: displayedEquipments.value[category.key],
})).filter(({ list }) => list?.length),
)
defineEmits<{
  close: []
}>()
</script>
