import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { Camper, AvailabilitiesListItemResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { addDays, compareAsc, differenceInDays, isSameDay, subDays } from 'date-fns'
import type { AttributeConfig } from 'v-calendar/dist/types/src/utils/attribute.js'
import type { DayOfWeek } from 'v-calendar/dist/types/src/utils/date/helpers.js'

export enum CalendarPeriod {
  BOOKING_EDGE_HALF_DAYS = 'bookingEdgeHalfDays',
  CLOSED_DAYS = 'closedDays',
  OWNER_PERIOD = 'ownerPeriod',
  BOOKING_MIN_DAYS = 'bookingMinDays',
}

interface AttributeConfigWithSingleDate extends AttributeConfig {
  dates: [Date]
}

type UnboundedDateRange = { start?: undefined, end: Date } | { start: Date, end: Date } | { start: Date, end?: undefined }
interface AttributeConfigWithSingleDateRange extends AttributeConfig {
  dates: [UnboundedDateRange]
}

export const useBookingRequestCalendarAttributes = ({
  openDays,
  availabilities,
  calendarSelectedDates,
  dragActiveMinBookingDays,
  hasForcedArrivalMorning,
  hasForcedDepartureAfternoon,
  bookingMinDays,
}: {
  openDays: MaybeRefOrGetter<Camper['open_days']>
  availabilities: MaybeRefOrGetter<AvailabilitiesListItemResponse[]>
  calendarSelectedDates: MaybeRefOrGetter<{ start?: Date | null, end?: Date | null }>
  dragActiveMinBookingDays: MaybeRefOrGetter<number>
  hasForcedArrivalMorning: MaybeRefOrGetter<boolean>
  hasForcedDepartureAfternoon: MaybeRefOrGetter<boolean>
  bookingMinDays: MaybeRefOrGetter<number>
}) => {
  const { t, n } = useI18n()

  const closedDaysAttributes = computed((): AttributeConfig[] => {
    const openDaysValue = toValue(openDays)

    if (!openDaysValue) {
      return []
    }

    const days: { dayIndex: DayOfWeek, active: boolean }[] = [
      { dayIndex: 1, active: openDaysValue.sun_is_open },
      { dayIndex: 2, active: openDaysValue.mon_is_open },
      { dayIndex: 3, active: openDaysValue.tue_is_open },
      { dayIndex: 4, active: openDaysValue.wed_is_open },
      { dayIndex: 5, active: openDaysValue.thu_is_open },
      { dayIndex: 6, active: openDaysValue.fri_is_open },
      { dayIndex: 7, active: openDaysValue.sat_is_open },
    ]

    return [
      {
        key: CalendarPeriod.CLOSED_DAYS,
        dates: [{
          start: new Date(),
          repeat: {
            every: 'week',
            weekdays: days.filter(({ active }) => !active).map(({ dayIndex }) => dayIndex),
          },
        }],
        popover: {
          hideIndicator: true,
          isInteractive: false,
          label: t('components.app_camper_booking_request_widget.owner_unavailable'),
        },
      },
    ]
  })

  // Store min booking days
  const ownerPeriodsAttributes = computed((): AttributeConfigWithSingleDateRange[] => {
    const availabilitiesValue = toValue(availabilities)

    const availabilitiesWithParsedDates = availabilitiesValue.filter(({ availability }) => availability === Constants.VEHICLES.AVAILABILITY.AVAILABLE).map(a => ({
      ...a,
      start: setDateAsStartDate(new Date(a.date_from)),
      end: setDateAsEndDate(new Date(a.date_to)),
    })).sort((a, b) => compareAsc(a.start, b.start))

    const defaultMinBookingDaysRanges: UnboundedDateRange[] = []
    if (availabilitiesWithParsedDates.length) {
      defaultMinBookingDaysRanges.push({
        end: setDateAsEndDate(subDays(availabilitiesWithParsedDates[0].start, 1)),
      })
    }

    for (let i = 0; i < availabilitiesWithParsedDates.length; i++) {
      const current = availabilitiesWithParsedDates[i]
      const next = availabilitiesWithParsedDates[i + 1]

      if (!next) {
        defaultMinBookingDaysRanges.push({
          start: setDateAsStartDate(addDays(current.end, 1)),
        })
      }
      else if (differenceInDays(next.start, current.end) > 1) {
        defaultMinBookingDaysRanges.push({
          start: setDateAsStartDate(addDays(current.end, 1)),
          end: setDateAsEndDate(subDays(next.start, 1)),
        })
      }
    }
    const periodWithDefaultMinBookingDays = defaultMinBookingDaysRanges.map((dateRange): AttributeConfigWithSingleDateRange => ({
      key: CalendarPeriod.OWNER_PERIOD,
      dates: [dateRange],
      customData: { bkg_days_min: toValue(bookingMinDays) },
    }))

    return availabilitiesWithParsedDates
      .map(({ date_from, date_to, bkg_days_min }): AttributeConfigWithSingleDateRange => ({
        key: CalendarPeriod.OWNER_PERIOD,
        dates: [{ start: setDateAsStartDate(new Date(date_from)), end: setDateAsEndDate(new Date(date_to)) }],
        customData: { bkg_days_min },
      })).concat(periodWithDefaultMinBookingDays)
  })

  const halfDays = computed((): {
    date: Date
    blockedOn: 'morning' | 'afternoon'
  }[] => {
    const availabilitiesValue = toValue(availabilities)

    const { DEPARTURE_AFTERNOON, ARRIVAL_MORNING } = Constants.BOOKINGS.HOURS
    const { NOT_AVAILABLE } = Constants.VEHICLES.AVAILABILITY

    return availabilitiesValue.filter(
      ({ availability, hour_from, hour_to }) =>
        availability === NOT_AVAILABLE && (hour_from === DEPARTURE_AFTERNOON || hour_to === ARRIVAL_MORNING),
    ).map(({ date_from, date_to, hour_from }) => hour_from === DEPARTURE_AFTERNOON
      ? {
          date: setDateAsStartDate(new Date(date_from)),
          blockedOn: 'afternoon',
        }
      : {
          date: setDateAsStartDate(new Date(date_to)),
          blockedOn: 'morning',
        })
  })

  const bookingEdgeHalfDaysAttributes = computed((): AttributeConfigWithSingleDate[] => {
    return halfDays.value.map(({ date, blockedOn }) =>
      blockedOn === 'afternoon'
        ? {
          // Force arrival morning
            key: CalendarPeriod.BOOKING_EDGE_HALF_DAYS,
            dates: [setDateAsStartDate(new Date(date))],
            customData: { newPeriodForceArrivalMorning: true },
            popover: {
              hideIndicator: true,
              isInteractive: false,
              label: t('components.app_camper_booking_request_widget.halfday_arrival_only'),
              visibility: 'hover-focus',
            },
          }
        : {
          // Force departure Afternoon
            key: CalendarPeriod.BOOKING_EDGE_HALF_DAYS,
            dates: [setDateAsStartDate(new Date(date))],
            customData: { newPeriodForceDepartureAfternoon: true },
            popover: {
              hideIndicator: true,
              isInteractive: false,
              label: t('components.app_camper_booking_request_widget.halfday_departure_only'),
              visibility: 'hover-focus',
            },
          },
    )
  })

  // Compute a period based on clicked day and min booking days to warn user
  const bookingMinDaysAttributes = computed((): AttributeConfigWithSingleDateRange[] => {
    const selectedDates = toValue(calendarSelectedDates)
    if (!selectedDates.start) {
      return []
    }

    const start = setDateAsStartDate(cloneDate(selectedDates.start))
    const end = setDateAsEndDate(cloneDate(selectedDates.start))

    // current day count as 1
    end.setDate(start.getDate() + toValue(dragActiveMinBookingDays) - 2)

    const isForcedArrivalMorning
      = toValue(hasForcedArrivalMorning) || halfDays.value.some(({ date, blockedOn }) => blockedOn === 'afternoon' && isSameDay(new Date(end).setDate(end.getDate() + 1), date))

    const isForcedDepartureAfternoon
      = toValue(hasForcedDepartureAfternoon) || halfDays.value.some(({ date, blockedOn }) => blockedOn === 'morning' && isSameDay(start, date))

    // If user can not book full days because of other constraints, need to add one day.
    if (isForcedArrivalMorning || isForcedDepartureAfternoon) {
      end.setDate(end.getDate() + 1)
    }

    return [
      {
        key: CalendarPeriod.BOOKING_MIN_DAYS,
        dates: [{ start, end }],
        popover: {
          hideIndicator: true,
          isInteractive: false,
          label: t('components.app_camper_booking_request_widget.min_booking_days_plural', {
            count_number: n(toValue(dragActiveMinBookingDays)),
          }, toValue(dragActiveMinBookingDays)),
        },
      },
    ]
  })

  const attributes = computed((): AttributeConfig[] => [
    ...bookingEdgeHalfDaysAttributes.value,
    ...closedDaysAttributes.value,
    ...ownerPeriodsAttributes.value,
    ...bookingMinDaysAttributes.value,
  ])

  return {
    closedDaysAttributes,
    ownerPeriodsAttributes,
    bookingEdgeHalfDaysAttributes,
    bookingMinDaysAttributes,
    attributes,
  }
}
