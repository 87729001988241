<script setup lang="ts">
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'

type HourRange = { from: number | null, to: number | null }

interface Props {
  value?: HourRange
  hasForcedDepartureMorning?: boolean
  hasForcedDepartureAfternoon?: boolean
  hasForcedArrivalMorning?: boolean
  hasForcedArrivalAfternoon?: boolean
  referentialHourToOptions: any[]
  referentialHourFromOptions: any[]
}

const props = withDefaults(defineProps<Props>(), {
  value: () => ({ from: null, to: null }),
  hasForcedDepartureMorning: false,
  hasForcedArrivalAfternoon: false,
})

const emits = defineEmits(['change'])

const form = ref<HourRange>({
  from: null,
  to: null,
})

watchEffect(() => {
  form.value.from = props.value.from
  form.value.to = props.value.to
})

const { t, d } = useI18n()

type HourOption = {
  disabled: boolean
  value: number
  text: string
}

const hourFromOptions = computed((): HourOption[] => {
  const { DEPARTURE_MORNING, DEPARTURE_AFTERNOON } = Constants.BOOKINGS.HOURS
  return props.referentialHourFromOptions.map(({ value }) => ({
    text: t('data.utils.hour_from.label_dynamic', {
      hour_string: d(new Date().setHours(value), { hour: 'numeric' }),
    }),
    value,
    disabled:
          (value === DEPARTURE_MORNING && props.hasForcedDepartureAfternoon)
          || (value === DEPARTURE_AFTERNOON && props.hasForcedDepartureMorning),
  }))
})

const hourToOptions = computed((): HourOption[] => {
  const { ARRIVAL_MORNING, ARRIVAL_AFTERNOON } = Constants.BOOKINGS.HOURS
  return props.referentialHourToOptions.map(({ value }) => ({
    text: t('data.utils.hour_to.label_dynamic', {
      hour_string: d(new Date().setHours(value), { hour: 'numeric' }),
    }),
    value,
    disabled:
          (value === ARRIVAL_MORNING && props.hasForcedArrivalAfternoon) || (value === ARRIVAL_AFTERNOON && props.hasForcedArrivalMorning),
  }))
})

const isHourFromSelectable = computed(() => hourFromOptions.value.filter(({ disabled }) => !disabled).length > 1)
const isHourToSelectable = computed(() => hourToOptions.value.filter(({ disabled }) => !disabled).length > 1)

const onFormChange = () => {
  emits('change', form.value)
}
</script>

<template>
  <div class="flex">
    <div
      class="relative w-1/2 rounded-bl border-b border-l border-gray-300 bg-white p-3 focus-within:z-10 focus-within:border-transparent focus-within:ring-2 focus-within:ring-blue-500"
    >
      <label
        for="hour-from"
        class="absolute top-2 block text-gray-500 text-sm"
      >
        {{ $t('data.booking_request.hour_from.label') }}
      </label>
      <select
        id="hour-from"
        v-model="form.from"
        :disabled="!isHourFromSelectable"
        name="hour-from"
        class="block w-full appearance-none bg-transparent cursor-pointer border-none p-0 pt-5 text-black focus-visible:ring-0"
        :class="{ 'cursor-not-allowed': !isHourFromSelectable }"
        required
        @change="onFormChange"
      >
        <option
          v-for="opt in hourFromOptions"
          :key="`hour-from-${opt.value}`"
          :value="opt.value"
          :disabled="opt.disabled"
        >
          {{ opt.text }}
        </option>
      </select>
      <YscIconsArrowBottom
        v-if="isHourFromSelectable"
        class="pointer-events-none absolute right-2 top-1/2 h-4 w-4"
      />
    </div>
    <div
      class="relative w-1/2 rounded-br border-x border-b border-gray-300 bg-white p-3 focus-within:z-10 focus-within:border-transparent focus-within:ring-2 focus-within:ring-blue-500"
    >
      <label
        for="hour-to"
        class="absolute top-2 block text-gray-500 text-sm"
      >
        {{ $t('data.booking_request.hour_to.label') }}
      </label>
      <select
        id="hour-to"
        v-model="form.to"
        :disabled="!isHourToSelectable"
        name="hour-to"
        class="block w-full appearance-none bg-transparent cursor-pointer border-none p-0 pt-5 text-black focus-within:ring-0"
        :class="{ 'cursor-not-allowed': !isHourToSelectable }"
        required
        @change="onFormChange"
      >
        <option
          v-for="opt in hourToOptions"
          :key="`hour-to-${opt.value}`"
          :value="opt.value"
          :disabled="opt.disabled"
        >
          {{ opt.text }}
        </option>
      </select>
      <YscIconsArrowBottom
        v-if="isHourToSelectable"
        class="pointer-events-none absolute right-2 top-1/2 h-4 w-4"
      />
    </div>
  </div>
</template>
