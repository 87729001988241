<script setup lang="ts">
interface Props {
  dateRange?: { start: Date | null, end: Date | null } | null
  hasHalfDayActivated?: boolean
}
withDefaults(defineProps<Props>(), {
  dateRange: null,
  hasHalfDayActivated: false,
})

const emit = defineEmits(['click'])

const onDateRangeClick = () => {
  emit('click')
}

const { localeProperties: { forceLocaleCode } } = useLocaleProperties()
</script>

<template>
  <div
    ref="date-picker-trigger"
    class="relative w-full cursor-pointer rounded-tl rounded-tr border border-gray-300 bg-white p-3"
    :class="{
      'rounded-bl rounded-br': !hasHalfDayActivated || !dateRange || !dateRange.start || !dateRange.end,
    }"
    @click.stop.prevent="onDateRangeClick"
  >
    <span class="absolute top-2 block text-sm text-gray-500">{{ $t('data.search.dates.placeholder') }}</span>
    <span class="flex items-center whitespace-nowrap p-0 pt-5 text-black">
      <template v-if="dateRange && dateRange.start && dateRange.end">
        <span>{{ $d(dateRange.start, 'Y.numeric M.short D.numeric', forceLocaleCode) }}</span>
        <YscIconsArrowRightLong class="mx-2 h-5 w-5" />
        <span>{{ $d(dateRange.end, "Y.numeric M.short D.numeric", forceLocaleCode) }}</span>
      </template>
      <template v-else>
        {{ $t('components.app_camper_booking_request_widget.dates.placeholder') }}
        <input
          id="date"
          type="date"
          name="dates"
          required
          tabindex="-1"
          class="absolute inset-0 -z-10 appearance-none opacity-0"
          @focus="onDateRangeClick"
        >
      </template>
    </span>
  </div>
</template>
