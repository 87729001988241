<script setup lang="ts">
import type { VehicleImageType, VehicleResponse } from '@yescapa-dev/ysc-api-js/legacy'
import type { RouteLocationRaw } from '#vue-router'

interface Props {
  title: string
  reviewCount: number
  reviewAverage: number
  vehiclePhotos: VehicleImageType[]
  addressLink: RouteLocationRaw
  addressText: string
  isNew: boolean
  isInWishlist: boolean
  showWishlist: boolean
  vehicleBrand: VehicleResponse['brand']
}

const props = withDefaults(defineProps<Props>(), {
  isNew: false,
  isInWishlist: false,
  showWishlist: false,
})

defineEmits<{
  'click-see-reviews': []
  'click-add-wishlist': []
  'click-share': []
  'click-see-all-photos': []
}>()

const { copyToClipboard } = useCopyToClipboard()

const placeholdersCount = Math.max(0, Math.min(2, 3 - props.vehiclePhotos.length))

const { getVehicleAlternateText } = useGetVehicleAlternateText()
</script>

<template>
  <div class="mt-4 sm:mt-0">
    <h1 class="truncate text-2xl font-semibold sm:text-3xl lg:text-4xl">
      {{ props.title }}
    </h1>

    <div class="mt-2 flex items-center justify-between gap-4 sm:mt-0">
      <div class="flex flex-wrap items-center gap-2 text-sm md:text-base">
        <div v-if="props.reviewCount || props.isNew">
          <template v-if="props.reviewCount">
            <div class="flex items-center space-x-1 after:sm:ml-2 after:sm:font-semibold after:sm:content-['•']">
              <YscIconsStarSolid class="inline-block h-4 w-4 text-pink-500" />

              <button
                class="link link-discreet font-light"
                @click="$emit('click-see-reviews')"
              >
                {{ $n(props.reviewAverage) }} ({{ $n(props.reviewCount) }})
              </button>
            </div>
          </template>

          <AppBadgeNew v-else-if="isNew" />
        </div>

        <div>
          <NuxtLink
            :to="props.addressLink"
            class="link link-discreet font-light"
          >
            {{ addressText }}
          </NuxtLink>
        </div>
      </div>

      <div>
        <button
          v-if="showWishlist"
          class="btn btn-link btn-icon sm:mr-1.5"
          name="wishlist"
          @click="$emit('click-add-wishlist')"
        >
          <YscIconsHeartSolid
            v-if="props.isInWishlist"
            class="h-4 w-4 text-pink-500"
          />
          <YscIconsHeart
            v-else
            class="h-4 w-4"
          />
        </button>

        <button
          class="btn btn-link btn-icon hidden sm:inline-flex"
          name="share"
          @click="copyToClipboard(useRequestURL().href)"
        >
          <YscIconsShare class="h-4 w-4" />
        </button>
      </div>
    </div>

    <!-- keep aspect ration to avoid small CLS -->
    <div class="aspect-[16/9] -mx-4 mt-4 grid gap-2 sm:mx-0 md:aspect-[8/3] md:grid-cols-3">
      <div class="relative md:col-span-2 md:row-span-2">
        <template v-if="props.vehiclePhotos.length">
          <YscPicture
            :path="twicpicsPath(props.vehiclePhotos[0].path)"
            :sources="[
              { breakpoint: 'xl', width: 830, height: 830 / 16 * 9, refitParameter: '(5p)' },
              { breakpoint: 'lg', width: 660, height: 660 / 16 * 9, refitParameter: '(5p)' },
              { breakpoint: 'md', width: 490, height: 490 / 16 * 9, refitParameter: '(5p)' },
              { breakpoint: 'sm', width: 610, height: 610 / 16 * 9, refitParameter: '(5p)' },
            ]"
            :default-source="{ width: 320, height: 180, refitParameter: '(5p)' }"
            with-refit-transformation
            fetchpriority="high"
            loading="eager"
            :alt="getVehicleAlternateText({ photoData: props.vehiclePhotos[0], vehicleBrand })"
            class="cursor-pointer sm:rounded md:rounded-none md:rounded-l"
            @click="$emit('click-see-all-photos')"
          />

          <button
            class="absolute bottom-3 right-3 flex items-center rounded bg-white border border-black px-3 py-1.5 text-xs md:bottom-4 md:left-4 md:right-auto md:text-sm"
            @click="$emit('click-see-all-photos')"
          >
            <span>{{ $t('commons.actions.see_all_photos') }}</span>
          </button>
        </template>
        <img
          v-else
          alt="-"
          data-twic-src="placeholder:auto"
          class="h-full rounded md:rounded-none md:rounded-l"
        >
      </div>
      <YscPicture
        v-for="(photo, i) in props.vehiclePhotos.slice(1, 3)"
        :key="photo.id"
        :path="twicpicsPath(photo.path)"
        :sources="[
          { breakpoint: 'xl', width: 410, height: 410 / 16 * 9, refitParameter: '(2p)' },
          { breakpoint: 'lg', width: 325, height: 325 / 16 * 9, refitParameter: '(2p)' },
          { breakpoint: 'md', width: 240, height: 240 / 16 * 9, refitParameter: '(2p)' },
        ]"
        :default-source="{ width: 240, height: 240 / 16 * 9, refitParameter: '(2p)' }"
        fetchpriority="high"
        loading="eager"
        :alt="getVehicleAlternateText({ photoData: photo, vehicleBrand })"
        with-refit-transformation
        :class="i === 0 ? 'md:rounded-tr' : 'md:rounded-br'"
        class="hidden cursor-pointer md:block"
        @click="$emit('click-see-all-photos')"
      />

      <div
        v-for="i in placeholdersCount"
        :key="`camper-hero-placeholder-${i}`"
        :class="i === 1 ? 'md:rounded-tr' : 'md:rounded-br'"
        class="hidden bg-gray-100 md:block"
      />
    </div>
  </div>
</template>
