<script setup lang="ts">
import type { Camper, VehicleResponse } from '@yescapa-dev/ysc-api-js/legacy'

interface Props {
  camper: Camper
  vehicle: VehicleResponse
  linkQueryMore: string
}

defineEmits<{
  openModal: [string]
}>()

const props = defineProps<Props>()

const { t, n } = useI18n()

const featuresOverview = computed(() => [
  { text: t('pages.campers.features.brand'), value: props.camper.title },
  { text: t('pages.campers.features.vehicle_year'), value: props.camper.vehicle_year },
  { text: t('pages.campers.features.gvw'), value: props.vehicle.gvw ? n(props.vehicle.gvw, { style: 'unit', unit: 'kilogram' }) : null },
  { text: t('pages.campers.features.vehicle_height'), value: props.vehicle.height ? n(props.vehicle.height, { style: 'unit', unit: 'meter' }) : null },
])
</script>

<template>
  <ul class="grid sm:grid-cols-2 gap-x-8 gap-y-4">
    <li
      v-for="item in featuresOverview"
      :key="item.text"
      class="flex flex-col"
    >
      <span class="font-semibold">{{ item.text }}</span>
      <span>{{ item.value || $t('commons.no_information') }}</span>
    </li>
  </ul>

  <div class="mt-2">
    <NuxtLink
      :to="useGetRouteWith({ query: { ...useRoute().query, more: linkQueryMore } })"
      class="link link-primary"
      use-icon
      arrow
      @click="$emit('openModal', linkQueryMore)"
    >
      {{ $t('pages.campers.see_all_features') }}
      <YscIconsArrowRight class="w-5 h-5" />
    </NuxtLink>
  </div>
</template>
