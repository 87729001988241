<script lang="ts" setup>
defineEmits<{
  close: []
}>()

defineProps<{
  hours: number
}>()

const { referential } = storeToRefs(useReferentialStore())
</script>

<template>
  <YscModal
    no-padding
    @close="$emit('close')"
  >
    <div class="px-4 space-y-3">
      <p>
        {{ $t('data.booking_request.errors.documents_not_validated.description_dynamic', { hour_number: hours }) }}
      </p>

      <p>
        <span class="font-semibold">
          {{ $t('components.app_modal_document_early_departure.follow_those_steps') }}
        </span>
        <ol class="list-disc list-inside mt-2">
          <li>{{ $t('components.app_modal_document_early_departure.steps.add_documents') }}</li>
          <i18n-t
            keypath="components.app_modal_document_early_departure.steps.send_email"
            tag="li"
          >
            <template #email_link>
              <NuxtLink
                class="link link-primary font-normal"
                :to="`mailto:${referential?.email_address}`"
              >
                {{ referential?.email_address }}
              </NuxtLink>
            </template>
          </i18n-t>
          <li>{{ $t('components.app_modal_document_early_departure.steps.request_booking') }}</li>
        </ol>
      </p>
    </div>

    <template #footer>
      <div class="flex flex-col md:flex-row gap-4 md:justify-between">
        <button
          class="btn btn-tertiary"
          @click="$emit('close')"
        >
          {{ $t('components.app_modal_document_early_departure.select_other_dates') }}
        </button>
        <NuxtLink
          class="btn btn-primary"
          :to="{ name: 'd-account-documents-guest' }"
        >
          {{ $t('components.app_modal_document_early_departure.link_add_documents') }}
        </NuxtLink>
      </div>
    </template>
  </YscModal>
</template>
