<template>
  <div
    class="flex items-start space-x-3 md:space-x-4"
  >
    <div class="shrink-0">
      <slot name="icon" />
    </div>

    <p class="flex flex-col whitespace-normal leading-tight">
      <span class="font-semibold text-base">
        <slot name="emphasis" />
      </span>
      <span class="text-sm text-gray-500">
        <slot name="description" />
      </span>
    </p>
  </div>
</template>
