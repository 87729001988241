<script setup lang="ts">
interface Props {
  firstName: string
  pictureUrl?: string | null
  isBestOwner?: boolean | null
  isVerified?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  pictureUrl: null,
  isBestOwner: false,
  isVerified: false,
})
</script>

<template>
  <div class="inline-flex items-center space-x-4">
    <YscAvatar
      :first-name="props.firstName ?? ''"
      :picture="props.pictureUrl"
      :is-best-owner="props.isBestOwner"
      :is-verified="props.isVerified"
      radius="20"
    />

    <div>
      <p class="font-semibold">
        {{ props.firstName }}
      </p>
      <p class="text-xs text-black">
        <slot name="description" />
      </p>
    </div>
  </div>
</template>
