<script setup lang="ts">
defineEmits<{
  close: []
}>()
const { t } = useI18n()
const { routing } = storeToRefs(useRoutingStore())
const steps = [
  {
    label: t('data.booking.states.options.TO_COME'),
    value: t('components.app_modal_camper_cancel_policy.step_to_come_description'),
    color: 'text-green-500',
  },
  {
    label: t('components.app_modal_camper_cancel_policy.step_to_come_too_late_label'),
    value: t('components.app_modal_camper_cancel_policy.step_to_come_too_late_description'),
    color: 'text-red-500',
  },
  {
    label: upperCaseFirstLetter(t('commons.departure')),
    color: 'text-red-500',
  },
]
</script>

<template>
  <YscModal @close="$emit('close')">
    <template #title>
      {{ $t('pages.campers.cancel_policy') }}
    </template>

    <YscSchema :steps="steps" />

    <div class="mt-12">
      <YscHeading level="3">
        {{ $t('components.app_modal_camper_cancel_policy.cancel_emergency') }}
      </YscHeading>

      <p class="mt-4">
        {{ $t('components.app_modal_camper_cancel_policy.cancel_emergency_description') }}
      </p>

      <p class="mt-2">
        {{ $t('components.app_modal_camper_cancel_policy.cancel_emergency_moneybox') }}
      </p>
    </div>

    <template #footer>
      <div class="flex justify-end">
        <NuxtLink
          class="link link-primary"
          :to="routing?.emergency_policy"
          target="_blank"
        >
          {{ $t('commons.actions.know_more') }}
        </NuxtLink>
      </div>
    </template>
  </YscModal>
</template>
