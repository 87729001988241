<script setup lang="ts">
import type { Camper, Currencies } from '@yescapa-dev/ysc-api-js'

const { t, n } = useI18n()
const { getReferentialMaps } = useReferentialStore()

interface Props {
  camper: Camper
}

const props = defineProps<Props>()

const depositItems = computed(() => {
  const currency = getReferentialMaps('currency')[props.camper.currency_used as Currencies]
  const currencyDeposit = { ...currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }
  const means = getReferentialMaps('deposit_mean')

  const items = []

  if (props.camper.yescapa_deposit) {
    items.push({
      key: 'deposit',
      label: t('data.product.deposit_mean.label'),
      value: t('pages.campers.deposit.deposit_yescapa'),
      icon: resolveComponent('YscIconsDepositMode'),
    })
    items.push({
      key: 'amount',
      label: t('pages.campers.deposit.amount'),
      value: t('pages.campers.owner_conditions.deposit_yescapa_value_dynamic'),
      icon: resolveComponent('YscIconsDeposit'),
    })
  }
  else {
    items.push({
      key: 'deposit',
      label: t('data.product.deposit_mean.label'),
      value: [t('pages.campers.deposit.deposit_owner')].concat(
        props.camper.deposit_mean.map(x => means[x]).filter(x => x).length
          ? props.camper.deposit_mean
            .map(x => means[x])
            .join(', ')
            .toLowerCase()
          : t('commons.no_information'),
      ).join(', '),
      icon: resolveComponent('YscIconsDepositMode'),
    })
    items.push({
      key: 'amount',
      label: t('pages.campers.deposit.amount'),
      value: n(props.camper.deposit || 0, currencyDeposit),
      icon: resolveComponent('YscIconsDeposit'),
    })
  }

  return items
})
</script>

<template>
  <ul class="grid sm:grid-cols-2 gap-x-8 gap-y-4">
    <li
      v-for="item in depositItems"
      :key="item.key"
      class="flex items-start gap-x-3"
    >
      <component
        :is="item.icon"
        class="h-6 w-6 shrink-0"
      />
      <div class="flex flex-col">
        <span class="font-semibold">{{ item.label }}</span>
        <span>{{ item.value }}</span>
      </div>
    </li>
  </ul>
</template>
