<script setup lang="ts">
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { Camper, UserDetailsResponse, VehicleResponse } from '@yescapa-dev/ysc-api-js/legacy'

interface Props {
  camper: Camper
  vehicle?: VehicleResponse | null
  owner?: UserDetailsResponse
  canShowMoreDescription: boolean
  linkQueryMore: string
}

defineEmits<{
  openModal: [string]
}>()

const props = defineProps<Props>()

const { t } = useI18n()

const title = computed(() => {
  if (!props.vehicle) {
    return ''
  }
  const value_string = props.camper.vehicle_owner_first_name
  switch (props.vehicle.type) {
    case Constants.PRODUCTS.TYPES.LOWPROFILE:
      return t('pages.campers.owner_introduce.lowprofile_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.COACHBUILT:
      return t('pages.campers.owner_introduce.coachbuilt_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.ACLASS:
      return t('pages.campers.owner_introduce.aclass_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.CAMPERVAN:
      return t('pages.campers.owner_introduce.campervan_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.VAN:
      return t('pages.campers.owner_introduce.van_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.CARAVAN:
      return t('pages.campers.owner_introduce.caravan_dynamic', { value_string })
    default:
      return t('pages.campers.owner_introduce.default_dynamic', { value_string })
  }
})
</script>

<template>
  <div>
    <div class="flex items-center gap-x-3 font-semibold mb-3">
      <YscAvatar
        :first-name="camper.vehicle_owner_first_name"
        :picture="owner?.profile_picture?.url"
        radius="16"
      />
      <span class="text-lg sm:text-2xl">{{ title }}</span>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="vehicle?.descriptions_default?.length"
      class="prose vehicle-description--wrapper line-clamp-3"
      v-html="vehicle.descriptions_default"
    />
    <!-- eslint-enable vue/no-v-html -->

    <p
      v-else
      class="italic"
    >
      {{ $t('pages.campers.no_description') }}
    </p>
    <div
      v-if="canShowMoreDescription"
      class="flex justify-between sm:justify-start sm:space-x-8"
    >
      <NuxtLink
        :to="useGetRouteWith({ query: { ...useRoute().query, more: linkQueryMore } })"
        class="link link-primary"
        @click="$emit('openModal', linkQueryMore)"
      >
        {{ $t('commons.actions.know_more') }}
      </NuxtLink>
    </div>
  </div>
</template>
