import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { AvailabilitiesListItemResponse, HourFrom, HourTo, BookingRequestPriceResponse, Camper } from '@yescapa-dev/ysc-api-js/legacy'
import { CAMPER_AVAILABITY_CHECK_EVENT } from '~/utils/analytics/camper'

export interface BookingRequestForm {
  dateRange: {
    start: Date | null
    end: Date | null
  } | null
  kilometers?: number | null
  hours: {
    from: number | null
    to: number | null
  }
}

export interface BookingRequestProps {
  bookingRequestForm: BookingRequestForm
  formId?: string
  camperId: string | number
  loading?: boolean
  bookingMinDays?: number
  prices?: BookingRequestPriceResponse | null
  weeklyDiscount?: boolean
  hasInstantBookingActivated?: boolean
  openDays?: Camper['open_days'] | null
  hasForcedDepartureMorning?: boolean
  hasForcedDepartureAfternoon?: boolean
  hasForcedArrivalMorning?: boolean
  hasForcedArrivalAfternoon?: boolean
  hasHalfDayActivated?: boolean
  is200kmFree?: boolean
  isUnlimitedKmActivated?: boolean
  isUnlimitedKmFree?: boolean
  weeklyFactor?: number | null
  isSelfInsured?: boolean
  availabilities: AvailabilitiesListItemResponse[]
  maxDateFutureRental: string
  minDateFutureRental: string
}

export type BookingRequestEmit = {
  (e: 'clear-form'): void
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  (e: 'submit'): void
  (e: 'update-prices', v: BookingRequestForm): void
}

export default function useBookingRequest({ props, emits, showCalendar }: { props: Required<BookingRequestProps>, emits: BookingRequestEmit, showCalendar?: Ref<boolean> }) {
  const formatKilometerPerDay = useFormatKilometerPerDay()
  const { $i18n: { t } } = useNuxtApp()

  const getFormInitialValue = () => {
    const initalFormValue: BookingRequestForm = {
      dateRange: { start: null, end: null },
      kilometers: props.bookingRequestForm?.kilometers,
      hours: {
        from: props.bookingRequestForm?.hours?.from,
        to: props.bookingRequestForm?.hours?.to,
      },
    }

    if (props.bookingRequestForm?.dateRange?.start && props.bookingRequestForm?.dateRange?.end) {
      initalFormValue.dateRange = {
        start: cloneDate(props.bookingRequestForm.dateRange.start),
        end: cloneDate(props.bookingRequestForm.dateRange.end),
      }
    }
    return initalFormValue
  }

  const form: Ref<BookingRequestForm> = ref(getFormInitialValue())

  const kilometersOptions = computed(() => [
    {
      value: 100,
      text: formatKilometerPerDay({ count: 100 }),
      active: (!props.isUnlimitedKmActivated || !props.isUnlimitedKmFree) && !props.is200kmFree,
    },
    {
      value: 200,
      text: formatKilometerPerDay({ count: 200 }),
      active: !props.isUnlimitedKmActivated || !props.isUnlimitedKmFree,
    },
    {
      value: 0,
      text: t('components.app_camper_booking_request_widget.km.unlimited'),
      active: props.isUnlimitedKmActivated,
    },
  ].filter(({ active }) => active))

  const hoursConstraintsForSelectedDate = computed((): {
    forceArrivalAfternoon: boolean
    forceArrivalMorning: boolean
    forceDepartureAfternoon: boolean
    forceDepartureMorning: boolean
  } => {
    const adConstraints = {
      forceDepartureMorning: props.hasForcedDepartureMorning,
      forceDepartureAfternoon: props.hasForcedDepartureAfternoon,
      forceArrivalMorning: props.hasForcedArrivalMorning,
      forceArrivalAfternoon: props.hasForcedArrivalAfternoon,
    }

    if (!form.value.dateRange) {
      return adConstraints
    }
    const { start, end } = form.value.dateRange
    if (!start || !end) {
      return adConstraints
    }

    const constraintsFromSelectedDate = validateHoursConstraintsForDateRange({ start, end }, props.availabilities, {
      booking_min_length: props.bookingMinDays,
    })
    // merge adConstraints and constraints returned by validateHoursConstraintsForDateRange
    return keysFromObject(adConstraints).reduce((acc, key) => {
      return {
        ...acc,
        [key]: constraintsFromSelectedDate[key] || acc[key],
      }
    }, adConstraints)
  })

  // weekly discount starts at 7 days, display tips if nb_days is close to 7
  const showWeeklyDiscountTip = computed(() => {
    if (!props.prices) {
      return false
    }
    return props.weeklyDiscount && [6, 6.5].includes(props.prices.nb_days)
  })

  const initForm = () => {
    // dateRange can be null because of v-calendar
    if (props.bookingRequestForm?.dateRange?.start && props.bookingRequestForm?.dateRange?.end) {
      form.value.dateRange = {
        start: cloneDate(props.bookingRequestForm.dateRange.start),
        end: cloneDate(props.bookingRequestForm.dateRange.end),
      }
    }
    form.value.kilometers = props.bookingRequestForm?.kilometers
    form.value.hours.from = props.bookingRequestForm?.hours?.from
    form.value.hours.to = props.bookingRequestForm?.hours?.to
  }

  const onHourChange = ({ from, to }: { from: HourFrom, to: HourTo }) => {
    form.value.hours.from = from
    form.value.hours.to = to
    onFormChange()
  }

  const onCalendarInput = async (evt: { start: Date | null, end: Date | null }) => {
    // wait for the second calendarDayClick first
    const { start = null, end = null } = evt || {}
    if (!start && !end) {
      form.value.dateRange = { start: null, end: null }
      if (showCalendar !== undefined) {
        showCalendar.value = true
      }
      onFormChange()
      return
    }

    form.value.dateRange = { start, end }
    if (showCalendar !== undefined) {
      showCalendar.value = false
    }

    const { DEPARTURE_MORNING, ARRIVAL_MORNING, DEPARTURE_AFTERNOON, ARRIVAL_AFTERNOON } = Constants.BOOKINGS.HOURS

    if (hoursConstraintsForSelectedDate.value.forceDepartureMorning) {
      form.value.hours.from = DEPARTURE_MORNING
    }
    else if (hoursConstraintsForSelectedDate.value.forceDepartureAfternoon) {
      form.value.hours.from = DEPARTURE_AFTERNOON
    }

    if (hoursConstraintsForSelectedDate.value.forceArrivalAfternoon) {
      form.value.hours.to = ARRIVAL_AFTERNOON
    }
    else if (hoursConstraintsForSelectedDate.value.forceArrivalMorning) {
      form.value.hours.to = ARRIVAL_MORNING
    }

    await nextTick()
    // Needed so the html can update and the required input "dates" is not rendered anymore.
    onFormChange()
  }

  const onFormChange = () => {
    if (form.value.dateRange?.start === null || form.value.dateRange?.end === null) {
      emits('clear-form')
    }
    if (form.value.dateRange?.start && form.value.dateRange?.end && form.value.kilometers !== null) {
      const { event } = useGtag()
      event(CAMPER_AVAILABITY_CHECK_EVENT)
      emits('update-prices', form.value)
    }
  }

  const onSubmit = () => {
    emits('submit')
  }

  onBeforeMount(() => {
    initForm()
  })

  return {
    form,
    kilometersOptions,
    hoursConstraintsForSelectedDate,
    showWeeklyDiscountTip,
    initForm,
    onHourChange,
    onCalendarInput,
    onFormChange,
    onSubmit,
  }
}
