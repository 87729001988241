<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { AvailabilitiesListItemResponse, Camper } from '@yescapa-dev/ysc-api-js/legacy'
import { areIntervalsOverlapping, endOfMonth, isEqual, isSameDay, min, parse, subMonths } from 'date-fns'
import type { Page, CalendarDay } from 'v-calendar/dist/types/src/utils/page.js'
import type { Attribute } from 'v-calendar/dist/types/src/utils/attribute.js'
import type { SimpleDateRange } from 'v-calendar/dist/types/src/utils/date/range.js'
import { selectAttribute, dragAttribute, setDateAsStartDate, setDateAsEndDate } from '~/utils/calendar'
import { useBookingRequestCalendarAttributes, CalendarPeriod } from '~/composables/camper/useBookingRequestCalendarAttributes'

const calendarConfig = {
  selectAttribute,
  dragAttribute,
}

const emit = defineEmits<{
  'page-change': [{ page_from: Date, page_to: Date }]
  'input': [{ start: Date | null, end: Date | null }]
}>()

interface Props {
  value?: {
    start: Date | null
    end: Date | null
  }
  show?: boolean
  availabilities: AvailabilitiesListItemResponse[]
  openDays?: Camper['open_days']
  bookingMinDays?: number
  hasForcedArrivalMorning?: boolean
  hasForcedDepartureAfternoon?: boolean
  hasHalfDayActivated: boolean
  isExpanded?: boolean
  showClearButton?: boolean
  maxDateFutureRental: string
  minDateFutureRental: string
}

const props = withDefaults(defineProps<Props>(), {
  value: () => ({
    start: null,
    end: null,
  }),
  show: false,
  bookingMinDays: 2,
  openDays: null,
  hasForcedArrivalMorning: false,
  hasForcedDepartureAfternoon: false,
  isExpanded: false,
  showClearButton: true,
})

const localValue = ref<{ start: Date | null, end: Date | null }>({
  start: props.value.start,
  end: props.value.end,
})

const calendar: Ref<{
  page_from: Date | null
  page_to: Date | null
  clickCount: number
  selection: {
    dates: {
      start?: Date | null
      end?: Date | null
    }
    bookingMinDays?: number | null
  }
}> = ref({
  page_from: null,
  page_to: null,
  clickCount: 0,
  selection: {
    dates: {
      start: null,
      end: null,
    },
    bookingMinDays: null,
  },
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const small = breakpoints.smaller('lg')
const screens = computed(() => small.value ? 1 : 2)

const now = useNow({ interval: 15_000 })

const hasSelectedDates = computed(() => localValue.value?.start && localValue.value?.end)
const minDateSelectable = computed(() => calendar.value.selection.dates.start || parse(props.minDateFutureRental, 'yyyy-MM-dd', new Date()))
const maxDateSelectableForCamper = computed(() => parse(props.maxDateFutureRental, 'yyyy-MM-dd', new Date()))
const maxDateSelectable = computed(() => {
  const calendarSelectionStart = calendar.value.selection.dates.start
  if (!calendarSelectionStart) {
    return maxDateSelectableForCamper.value
  }

  const nextDisabledPeriod = disabledDates.value
    .filter(({ start }) => start > calendarSelectionStart)
    .sort((a, b) => b.start.getTime() - a.start.getTime())
    .pop()
  return nextDisabledPeriod ? min([nextDisabledPeriod.start, maxDateSelectableForCamper.value]) : maxDateSelectableForCamper.value
})

// Prevents from overlapping selection
// Specific condition for mandatory documents have been removed, see https://yescapa.atlassian.net/browse/TT-4098
const disabledDates = computed((): { start: Date, end: Date }[] => {
  const { DEPARTURE_AFTERNOON, ARRIVAL_MORNING } = Constants.BOOKINGS.HOURS
  return props.availabilities
    .filter(({ availability }) => availability === Constants.VEHICLES.AVAILABILITY.NOT_AVAILABLE)
    .map(({ date_from, hour_from, date_to, hour_to }) => {
      const start = setDateAsStartDate(new Date(date_from))
      const end = setDateAsEndDate(new Date(date_to))

      if (props.hasHalfDayActivated) {
        // Half day cases, free the previous / next day
        if (hour_from === DEPARTURE_AFTERNOON) {
          start.setDate(start.getDate() + 1)
        }
        if (hour_to === ARRIVAL_MORNING) {
          end.setDate(end.getDate() - 1)
        }
      }
      return { start, end }
    })
})

// By default each camper has a min booking days
// it can be overridden by owner periods
// Keep the maximum between multiple values
// Compute the value based on the date the user is currently dragging in the calendar
const dragActiveMinBookingDays = computed(() => calendar.value.selection.bookingMinDays ? calendar.value.selection.bookingMinDays : props.bookingMinDays)

const { openDays, hasForcedDepartureAfternoon, hasForcedArrivalMorning, availabilities, bookingMinDays } = toRefs(props)
const {
  ownerPeriodsAttributes,
  attributes,
} = useBookingRequestCalendarAttributes({
  openDays,
  availabilities,
  dragActiveMinBookingDays,
  hasForcedArrivalMorning,
  hasForcedDepartureAfternoon,
  calendarSelectedDates: () => calendar.value.selection.dates,
  bookingMinDays,
})

watch(() => props.show, (val) => {
  if (!val) {
    resetCalendarData()
  }
})

const clearRange = () => {
  localValue.value = { start: null, end: null }
}

defineExpose({
  clearRange,
})

const resetCalendarData = () => {
  calendar.value.clickCount = 0
  calendar.value.selection.dates.start = null
  calendar.value.selection.dates.end = null
  calendar.value.selection.bookingMinDays = null
}

// START - Calendar event handlers
const onTransitionStart = () => {
  const { page_from, page_to } = calendar.value
  if (!page_from || !page_to) return
  emit('page-change', { page_from, page_to })
}

const onCalendarUpdatePages = (pages: Page[]) => {
  // vcalendar month index is +1 compared to UTC
  calendar.value.page_from = subMonths(new Date(Date.UTC(pages[0].year, pages[0].month)), 2)
  if (pages.length > 1) {
    const { year, month } = pages[pages.length - 1]
    calendar.value.page_to = new Date(Date.UTC(year, month))
  }
  else {
    calendar.value.page_to = endOfMonth(calendar.value.page_from)
  }
}

// Compute max bkg_days_min of all overlapping owner periods
const onCalendarDrag = (evt: SimpleDateRange | null) => {
  if (!evt) {
    return
  }
  const { start, end } = evt
  const interval = {
    start: setDateAsStartDate(start),
    end: setDateAsEndDate(end),
  }
  calendar.value.selection.dates.start = interval.start
  calendar.value.selection.dates.end = interval.end

  calendar.value.selection.bookingMinDays = ownerPeriodsAttributes.value
    .filter(({ dates }) => {
      const dateRange = dates[0]

      if (dateRange.start && dateRange.end) {
        return areIntervalsOverlapping(dateRange, interval)
      }
      else if (dateRange.start) {
        return end.getTime() >= dateRange.start.getTime()
      }
      else {
        return start.getTime() <= dateRange.end.getTime()
      }
    })
    .reduce((acc, { customData: { bkg_days_min = 0 } = {} }) => Math.max(acc, bkg_days_min ?? 0), 0)
}

const onCalendarDayClick = () => {
  calendar.value.clickCount++
}

const onLocalValueChange = () => {
  // FIXME
  emit('input', localValue.value)
}

const onCalendarInput = async (event: { start?: Date | null, end?: Date | null }) => {
  // wait for the second calendarDayClick first
  await nextTick()
  const { start = null, end = null } = event || {}
  if (start !== null && end !== null && isSameDay(start, end)) {
    clearRange()
    resetCalendarData()
    return
  }
  localValue.value = { start, end }
  if (calendar.value.clickCount >= 2) {
    resetCalendarData()
  }
  onLocalValueChange()
}
// END - Calendar Event handler

// START - Calendar Day configuration methods
/** Make closed days and booking min days periods unclickable */
const setupDayListeners = ({ attributes, dayEvents, day }: { attributes: Attribute[], day: CalendarDay, dayEvents: any }) => {
  // Do not remove click event from the selection start day so the user can click it a second time to reset their selection
  const selectDragAttribute = attributes.find(({ key }) => key === 'select-drag')
  const hasSelectDrag = !!selectDragAttribute
  const isStartDay = hasSelectDrag && selectDragAttribute.ranges[0].start?.dateTime && isSameDay(selectDragAttribute.ranges[0].start?.dateTime, day.date)

  const isClosedDay = attributes.find(({ key }) => key === CalendarPeriod.CLOSED_DAYS)
  const hasBookingMinDays = !!attributes.find(({ key }) => key === CalendarPeriod.BOOKING_MIN_DAYS)

  const draggedOrStart = hasSelectDrag && !isStartDay && (!!isClosedDay || hasBookingMinDays)

  return draggedOrStart || isClosedDay
    ? omit('click', dayEvents)
    : dayEvents
}

const getDayClasses = ({ attributes, day }: { attributes: Attribute[], day: CalendarDay }) => {
  const isClosedDay = attributes && !!attributes.find(({ key }) => key === CalendarPeriod.CLOSED_DAYS)
  const isBookingMinDay = attributes && !!attributes.find(({ key }) => key === CalendarPeriod.BOOKING_MIN_DAYS)

  const cssClasses: any[] = [
    {
      'cursor-default': day.isDisabled || isClosedDay || isBookingMinDay,
      'text-gray-400 font-light': day.isDisabled,
      'text-gray-500': !day.isDisabled && (isClosedDay || isBookingMinDay),
      'pointer-events-none line-through after:bg-white/80': day.isDisabled,
      'hover:cursor-pointer': !day.isDisabled && !isClosedDay && !isBookingMinDay,
    },
  ]

  const selectDragAttribute = attributes?.find(({ key }) => key === 'select-drag')
  if (selectDragAttribute) {
    const { ranges, highlight } = selectDragAttribute
    if (ranges[0].start?.dateTime !== undefined && isEqual(day.date, ranges[0].start?.dateTime)) {
      cssClasses.push(highlight?.start.contentClass)
    }
    else if (ranges[0].end?.dateTime !== undefined && isSameDay(day.date, ranges[0].end?.dateTime)) {
      cssClasses.push(highlight?.end.contentClass)
    }
  }

  return cssClasses
}

const getPopover = (attributes: Attribute[]) => {
  if (!attributes.length) {
    return null
  }

  const { CLOSED_DAYS, BOOKING_EDGE_HALF_DAYS, BOOKING_MIN_DAYS } = CalendarPeriod

  return attributes.find(({ key }) => ([CLOSED_DAYS, BOOKING_EDGE_HALF_DAYS, BOOKING_MIN_DAYS] as string[]).includes(key.toString()))
}
// END - Calendar Day configuration methods

const { localeProperties: { forceLocaleCode } } = useLocaleProperties()

const initialPage = computed(() => {
  const currentDate = props.value.start ?? now.value
  return {
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
  }
})
</script>

<template>
  <div class="w-full">
    <ClientOnly>
      <DatePicker
        v-if="props.show"
        ref="date-picker"
        v-model.range="localValue"
        color="pink"
        class="customCalendar"
        :attributes="attributes"
        :initial-page="initialPage"
        :initial-page-position="1"
        :min-date="minDateSelectable"
        :max-date="maxDateSelectable"
        :disabled-dates="disabledDates"
        :drag-attribute="calendarConfig.dragAttribute"
        :select-attribute="calendarConfig.selectAttribute"
        :popover="{ visibility: 'focus' }"
        :expanded="props.isExpanded"
        trim-weeks
        :columns="screens"
        :locale="forceLocaleCode"
        @drag="onCalendarDrag"
        @dayclick="onCalendarDayClick"
        @update:pages="onCalendarUpdatePages"
        @update:model-value="onCalendarInput($event)"
        @transition-start="onTransitionStart"
      >
        <template #day-content="{ day, attributes: attr, dayEvents }">
          <span
            :class="getDayClasses({ day, attributes: attr })"
            class="text-sm-technic flex h-full items-center justify-center font-semibold tabular-nums"
            v-on="setupDayListeners({ attributes: attr, dayEvents, day })"
          >
            {{ day.label }}
          </span>
        </template>

        <template #day-popover="{ attributes: popoverAttributes }">
          <p
            v-if="getPopover(popoverAttributes)"
          >
            {{ getPopover(popoverAttributes)?.popover?.label }}
          </p>
        </template>

        <template
          v-if="showClearButton && hasSelectedDates"
          #footer
        >
          <div class="flex justify-end p-2">
            <button
              class="btn btn-secondary btn-link text-sm"
              @click.stop.prevent="clearRange"
            >
              {{ $t('components.app_search.filters.remove_filters') }}
            </button>
          </div>
        </template>
      </DatePicker>
    </ClientOnly>
  </div>
</template>

<style scoped>
.customCalendar :deep(.vc-container) {
  .vc-day {
    @apply aspect-square md:aspect-[unset];
  }

  .vc-highlight {
    height: 95%;
  }

  .vc-day-layer.vc-day-box-center-center .vc-highlight {
    @apply w-full;
  }
}
</style>
